<template>
  <div>
    <div class="button-wrapper">
        <button :disabled="status==='submitting'" class="button is-zen-wide-button" v-on:click="submit">GET STARTED</button>
    </div>

    <vue-recaptcha sitekey="6Lew8cgZAAAAAOtLbbp064x1Xsjy214D0IWMVJk_"
                   :loadRecaptchaScript="true"
                   ref="recaptcha"
                   @verify="onCaptchaVerified"
                   @expired="onCaptchaExpired"
                   size="invisible"
                   ></vue-recaptcha>
  </div>

</template>


<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: {VueRecaptcha},

  data() {
    return {
      status: ""
    };
  },

  methods: {
    submit() {
      this.status = "submitting";
      this.$refs.recaptcha.execute();
    },

    async onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();

      const {data} = await this.$http.post('web_auth', {
        token: recaptchaToken
      })

      if (data['success'] == true) {
        this.$store.dispatch("loadData");
        Turbolinks.visit('/users/dashboard');
      } else {
        this.$buefy.toast.open({
          message: 'Sorry, the captcha authentication not successfull. Pleas reload the page and try again.',
          type: 'is-red'
        })
      }

    },

    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    }

  }
}
;
</script>