import Vue from 'vue/dist/vue.esm';
import axios from 'axios';

import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {
      currency: undefined,
      check_interval: undefined,
      next_check_text: '',
      authenticated: false,
      supporter: false,
      number_coins: 0,
      inform: false
    },
    system: {
      UnauthenticatedCoinLimit: 0,
      AuthenticatedCoinLimit: 0,
      ThresholdLimit: 0,
      telegram_bot_name: document.querySelector("meta[name='telegram_bot_name']").getAttribute('content'),
      telegram_bot_id: document.querySelector("meta[name='telegram_bot_id']").getAttribute('content')
    }
  },

  actions: {
    async loadData(context) {
      const url = '/users.json'
      const {data} = await axios.get(url)

      if (data['user']) {
        context.commit('updateUser', data['user'])
        context.state.system.ThresholdLimit = parseInt(data['system']['threshold_limit'])
        context.state.system.UnauthenticatedCoinLimit = parseInt(data['system']['unauthenticated_coin_limit'])
        context.state.system.AuthenticatedCoinLimit = parseInt(data['system']['authenticated_coin_limit'])
      } else {
        context.commit('removeUser')
      }
    }
  },

  mutations: {
    async updateUser(state, user) {
      state['user'].loggedIn = true
      state['user'].currency = user['currency']
      state['user'].check_interval = user['check_interval']
      state['user'].next_check_text = user['next_check_text']
      state['user'].authenticated = user['authenticated']
      state['user'].supporter = user['supporter']
      state['user'].inform = user['inform']
    },

    removeUser(state) {
      state['user'].loggedIn = false
      state['user'].currency = undefined
      state['user'].check_interval = undefined
      state['user'].next_check_text = ''
      state['user'].authenticated = false
      state['user'].supporter = false
      state['user'].number_coins = 0
      state['user'].inform = false
    }
  },

  getters: {
    loggedIn: state => {
      return state.user['currency'] ? true : false
    },

    authenticated: state => {
      return state['user'].authenticated
    },

    canAddCoins: state => {
      if (
        state['user'].number_coins < state.system.UnauthenticatedCoinLimit ||
        state['user'].number_coins < state.system.AuthenticatedCoinLimit && state['user'].authenticated ||
        state['user'].supporter
      ) {
        return true
      }

      return false

    }
  }
});

export default store;