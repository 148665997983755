import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';

import store from "../components/store"

import Buefy from 'buefy'
Vue.use(Buefy)

import CoinStates from '../components/coin_states.vue';
import Session from '../components/session.vue';
import Settings from '../components/settings.vue';
import Recaptcha from '../components/recaptcha.vue';

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

// setting up axios for all components
import axios from 'axios';
axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
Vue.prototype.$http = axios

Vue.use(TurbolinksAdapter)
Vue.component('coin_states', CoinStates)
Vue.component('Session', Session)
Vue.component('settings', Settings)
Vue.component('recaptcha', Recaptcha)


document.addEventListener('turbolinks:load', () => {

  new Vue(
    {
      store,
      el: '[data-behavior="vue"]'
    }
  )
})