<template>
    <div class="container" v-if="dataLoaded">
        <div class="coin_states-list" v-if="showStates">
            <div v-if="coins.length > 0">
                <div v-if="this.$store.getters.canAddCoins">
                    <button class="button is-small is-zen-button-action addCoinButton is-pulled-right header-link"
                            v-on:click="toggleCoinChooser">Add coins
                    </button>
                </div>
                <div v-else-if="this.$store.state.user.authenticated">
                    <div class="is-pulled-right has-text-info header-link">
                        <i class="fas fa-exclamation-circle"></i>
                        Coin limit reached
                    </div>
                </div>

                <div class="title">
                    Your crypto
                </div>
                <div class="coin-states">
                    <div v-for="coin in coins" :key="coin.name" :ref="'coin-state-' + coin['id']">
                        <div class="coin-state is-clearfix">
                            <div class="coin-state-text is-clearfix" v-on:click="toggleCrud(coin.id, $event)">
                                <img :src="coin['image']" class="is-pulled-left"/>
                                <div class="coin-detail is-pulled-left">
                                    {{ coin['name'] }}
                                    <span v-html="coin['state']"></span>
                                </div>
                                <i class="fas fa-caret-down is-pulled-right" :ref="'coinCrudArrow' + coin['id']"></i>
                            </div>
                            <div class="coin_edit is-clearfix">
                                <CoinCrud class="CoinCrud" action='Edit' :coin=coin
                                          @thresholdChanged="thresholdChanged(coin.id)"
                                          :ref="'coinCrudComponent' + coin['id']"></CoinCrud>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coin-footer">
                    <div v-if="this.$store.state.user.authenticated">
                        <button class="button is-small is-success refresh-button is-pulled-left" v-on:click="loadCoins">
                        <span class="icon">
                          <i :class="spinner + ' fas fa-sync'"></i>
                        </span>
                        </button>
                        <div class="is-pulled-left secondary-text">
                            updated: {{ updated_at }} ago
                        </div>
                        <div class="is-pulled-right secondary-text">
                            Bot connected
                        </div>
                    </div>
                    <div v-else class="content">
                        <div v-if="!this.$store.getters.canAddCoins && !this.$store.state.user.authenticated">
                            <div class="notification is-danger">
                                <span>Preview coin limit reached.</span>
                                <span>
                                    <br>
                                    Please authenticate via Telegram to prove you are human.
                                </span>

                            </div>
                        </div>
                        <Session></Session>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="content">
                    <div class="title">
                        Glad to see you here
                    </div>
                    <div class="is-size-6 is-white has-text-weight-semibold">
                        <p>
                            Now make your first steps towards Zen hodling:
                        </p>

                        <div class="numbering">
                        </div>
                        <div class="text is-clearfix">
                            Set your currency
                        </div>

                        <div class="numbering">
                        </div>
                        <div class="text is-clearfix">
                            Add a coin and some relevant price points
                        </div>


                        <div class="numbering">
                        </div>
                        <div class="text is-clearfix">
                            Connect with the Telegram bot
                        </div>


                    </div>
                    <p>
                        <button class="button is-zen-wide-button addCoinButton"
                                v-on:click="toggleCoinChooser">Add your first coin now
                        </button>
                    </p>

                </div>

            </div>

        </div>
        <div v-else>
            <a class="is-small is-pulled-right back-link" v-on:click="toggleCoinChooser" v-if="coins.length > 0">
                <span v-if="coins.length > 0">
                    <i class="fas fa-angle-left"></i>
                    Your crypto
                </span>
            </a>

            <div class="title">
                Adding coins
            </div>
            <CoinList @thresholdChanged="thresholdChanged" @toggleCoinChooser="toggleCoinChooser" class="CoinList"
                      ref="CoinChooser"></CoinList>
        </div> &nbsp;

    </div>
</template>

<script>

import CoinList from './coin_list'
import CoinCrud from "./coin_crud";
import Session from "./session";

export default {

  data() {
    return {
      coins: [],
      updated_at: '',
      spinner: '',
      showStates: true,
      dataLoaded: false
    };
  },

  components: {
    CoinList, CoinCrud, Session
  },

  mounted() {
    this.loadCoins()
  },

  computed: {
    currency_changed() {
      return this.$store.state.user.currency
    }
  },

  watch: {
    currency_changed() {
      this.loadCoins()
    }
  },

  methods: {
    toggleCoinChooser() {

      this.showStates = !this.showStates
      if (this.$refs.CoinChooser) {
        this.$refs.CoinChooser.activate()
      }
    },

    toggleCrud(coin_id, event) {
      this.$refs["coinCrudComponent" + coin_id][0].toggleDialogue()
      this.$refs["coinCrudArrow" + coin_id][0].classList.toggle('icon-rotate');
    },

    thresholdChanged(coin_id) {

      if (this.$refs["coin-state-" + coin_id] == undefined || this.$refs["coin-state-" + coin_id].length == 0) {
        this.loadCoins()
      } else {
        this.updateCoin(coin_id)
      }
    },

    async loadCoins() {
      const url = '/thresholds/state.json'
      this.spinner = 'fa-spin'
      const {data} = await this.$http.get(url)
      this.coins = data['coins']
      this.updated_at = data['updated_at']
      this.spinner = ''
      this.dataLoaded = true
      this.$store.state.user.number_coins = this.coins.length
    },

    async updateCoin(coin_id) {
      const url = '/thresholds/state.json' + '/?coin_id=' + coin_id
      const {data} = await this.$http.get(url)

      if (data['coins'][0] != undefined) {
        let coin = this.coins.find(coin => coin['id'] == coin_id);
        coin['state'] = data['coins'][0]['state']
        coin['name'] = data['coins'][0]['name']

      } else {

        this.coins = this.coins.filter(function (coin) {
          return coin['id'] !== coin_id;
        });

      }

      this.$store.state.user.number_coins = this.coins.length

    }
  },

}

</script>