<template>
    <div class="container settings_component">

        <div class="field">
            <label class="label">
                Currency
                <b-tooltip label="Changing the currency will not adjust existing price points."
                           position="is-bottom"
                           type="is-light"
                           v-if="this.$store.state.user.number_coins > 0"
                           multilined>
                    <i class="fas fa-question-circle"></i>
                </b-tooltip>
            </label>
            <div class="control">
                <div class="select">
                    <select v-model="user['currency']" v-on:change="updateUser">
                        <option value="EUR">EUR</option>
                        <option value="USD">USD</option>
                    </select>
                </div>
            </div>
        </div>

        <div v-if="this.user.authenticated">
            <div class="field">
                <label class="label">
                    Check frequency
                    <b-tooltip label="Sets the minimum time between Telegram notifications. Higher time equals less distractions."
                               position="is-bottom"
                               type="is-light"
                               multilined>
                        <i class="fas fa-question-circle"></i>
                    </b-tooltip>
                </label>
                <div class="control">
                    <div class="select">
                        <select v-model="user['check_interval']" v-on:change="updateUser">
                            <option value="1800">30 minutes</option>
                            <option value="3600">1 hour</option>
                            <option value="10800">3 hours</option>
                            <option value="21600">6 hours</option>
                            <option value="43200">12 hours</option>
                            <option value="86400">1 day</option>
                            <option value="604800">1 week</option>
                            <option value="0">Never</option>
                        </select>
                    </div>
                </div>

                <p class="secondary-text">
                    {{ user['next_check_text'] }}
                </p>


            </div>
            <div class="field">
                <label class="label">
                    Inform me about changes
                    <b-tooltip label="Very rare (possibly no) notifications about fundamental changes or if this service ever goes offline."
                               position="is-bottom"
                               type="is-light"
                               multilined>
                        <i class="fas fa-question-circle"></i>
                    </b-tooltip>
                </label>
                <div class="control">
                    <div class="select">
                        <select v-model="user['inform']" v-on:change="updateUser">
                            <option value="true">yes</option>
                            <option value="false">no</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr style="margin-top: 25px;">
            <p>
                <a class="has-text-weight-bold" v-on:click="confirmCustom">Delete account</a>
            </p>
        </div>

    </div>
</template>

<script>

export default {
  data() {
    return {
      user: {
        currency: undefined,
        check_interval: undefined,
        next_check_text: undefined,
        authenticated: false,
        inform: false
      }
    };
  },

  mounted() {
    this.init()
  },

  computed: {
    user_changed() {
      return [this.$store.getters.loggedIn, this.$store.getters.authenticated]
    }
  },

  watch: {
    user_changed() {
      this.init()
    }
  },

  methods: {

    init() {
      /* fyi
          I first did this.user = this.$store.state.user
          This seems to assign by reference and changing the select options directly changes the vuex store
          This in turn led to a premature watcher execution in states.vue, loading coins, before the user actually got updated in the DB from here
      */

      this.$store.dispatch('loadData')

      this.user.currency = this.$store.state.user.currency
      this.user.check_interval = this.$store.state.user.check_interval
      this.user.next_check_text = this.$store.state.user.next_check_text
      this.user.authenticated = this.$store.state.user.authenticated
      this.user.inform = this.$store.state.user.inform

    },

    async updateUser() {
      if (this.$store.getters.loggedIn) {
        const url = '/users.json'
        const {data} = await this.$http.put(url, {
          "user": this.user
        })

        this.$store.state.user = data['user']
      }

    },

    confirmCustom() {
      this.$buefy.dialog.confirm({
        title: 'Confirm user deletion',
        message: `This will delete the current user and all data!`,
        cancelText: 'No rather not',
        confirmText: 'OK',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteUser()
      })
    },

    async deleteUser() {
      const url = '/users.json'
      const {data} = await this.$http.delete(url)
      Turbolinks.visit('/?delete=1');
    }

  },

}

</script>