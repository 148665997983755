<template>
    <div v-if="authenticated">
        <!--        <figure class="image is-32x32">-->
        <!--            <img v-bind:src="userImage">-->
        <!--        </figure>-->
        <button class="button is-small is-light" v-on:click="logoutUser">
            <i class="fas fa-sign-out-alt"></i>
            <span v-if="!$isMobile()" class="logout-text">
                Log out
            </span>
        </button>
    </div>
    <div v-else>
        <div v-if="loggedIn" class="telegram-temp-user">
            <br>
            <p class="has-text-white">
                Get notified when price points are surpassed by connecting with Telegram.
                <br><br>
                <button class="button is-blue-button is-zen-button" v-on:click="telegramAuth">
                    <i class="fab fa-telegram-plane"></i>
                    Connect with Telegram
                </button>
            </p>
        </div>
        <div v-else>
            <button class="button is-small is-blue-button" v-on:click="telegramAuth">
                <i class="fab fa-telegram-plane telegram-plane"></i>
                <span class="connect_text">
                    Log in
                </span>
            </button>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Login',

  data() {
    return {
      loggedIn: this.$store.getters.loggedIn,
      authenticated: this.$store.getters.authenticated
    };
  },

  computed: {
    user_changed() {
      return [this.$store.getters.loggedIn, this.$store.getters.authenticated]
    }
  },

  watch: {
    user_changed() {
      this.loggedIn = this.$store.getters.loggedIn
      this.authenticated = this.$store.getters.authenticated
    }
  },

  mounted() {
    let telegram_script = document.createElement('script')
    telegram_script.setAttribute('src', 'https://telegram.org/js/telegram-widget.js')
    document.head.appendChild(telegram_script)
  },

  async created() {
    if (window.location.href.includes("delete=1")) {
      this.$buefy.toast.open({
        message: 'User deleted',
        type: 'is-red'
      })
    }
    this.$store.dispatch("loadData");
  },

  methods: {

    telegramAuth() {
      window.Telegram.Login.auth(
        {bot_id: this.$store.state.system.telegram_bot_id, request_access: true},
        (data) => {
          if (!data) {
            console.log('auth failed')
          }
          this.loginUser(data);
        }
      );
    },

    async loginUser(user) {
      let params = Object.keys(user).map(key => key + '=' + user[key]).join('&');
      const url = '/telegram_auth?' + params
      const {data} = await this.$http.get(url)

      if (data['success'] == true) {
        Turbolinks.visit('/users/dashboard');
      } else {
        this.$buefy.toast.open({
          message: 'Signup failed :(',
          type: 'is-red'
        })
      }

    },

    async logoutUser() {
      const url = '/log_out'
      const {data} = await this.$http.get(url)
      Turbolinks.visit('/');
    },

  }
}
</script>