<template>
    <div ref="CoinCrud" class="is-hidden">
        <div v-if="action == 'Add'" class="field">
            Which prices would you like to watch?
        </div>

        <form @submit="addThreshold">
            <div class="field">
                <div class="control is-pulled-left" v-if="thresholds.length < this.$store.state.system.ThresholdLimit || this.$store.state.user.supporter">
                    <input class="input is-small" type="text" name="thresholds" :placeholder="'Add price points in ' + this.$store.state.user['currency']"
                           autocomplete="off"
                           v-model="new_thresholds" ref="AddThresholdInput" width="100" v-on:keyup="validateInput">
                    <button type="submit" class="button is-small add-threshold-button" ref="AddThresholdButton">
                        Add
                    </button>
                    <div class="is-size-7 is-hidden invalid-text" ref="AddThresholdInvalidText">
                        Invalid input!
                        Use dots as decimal separator and spaces between price points.
                    </div>

                </div>
                <div v-else class="limit-reached">
                    Price point limit reached
                </div>
                <button class="button is-small is-danger is-pulled-right" v-on:click="removeAll"
                        v-if="thresholds.length > 0 && action != 'Add'">
                    Remove
                </button>
                &nbsp;
            </div>
        </form>
        <div class="thresholds">
            <span class="tag is-small is-success" v-for="threshold in thresholds">
                {{ threshold['price'] }}
                <button class="delete is-small" v-on:click="removeThreshold($event, threshold['id'])"></button>
            </span>
        </div>
    </div>
</template>

<script>
export default {

  data() {
    return {
      new_thresholds: '',
      thresholds: []
    };
  },

  props: ['coin', 'action'],

  methods: {

    toggleDialogue() {

      this.$refs.CoinCrud.classList.toggle('is-hidden')
      this.$refs.AddThresholdInput.focus()

      if (this.action != 'Add' && !this.$refs.CoinCrud.classList.contains('is-hidden')) {
        this.getThresholds()
      }

    },

    async removeThreshold(event, id) {
      const url = '/thresholds/' + id + '.json'
      const {data} = await this.$http.delete(url)

      this.thresholds = this.thresholds.filter(function (threshold) {
        return threshold['id'] !== id;
      });

      this.$emit("thresholdChanged", this.coin['id'], this.thresholds.length);
      this.$refs.AddThresholdInput.focus()

    },

    async addThreshold(e) {
      e.preventDefault();

      if (!this.validateInput(true)) {
        return;
      }

      const url = '/thresholds.json'
      const {data} = await this.$http.post(url, {
        thresholds: this.new_thresholds,
        coin: this.coin['name_short']
      })

      await this.getThresholds()
      this.new_thresholds = ''
      this.$refs.AddThresholdInput.focus()

      this.$emit("thresholdChanged", this.coin['id'], this.thresholds.length);

    },

    validateInput(onSubmit = false) {
      let regex = /^([0-9]+(\.[0-9]+)?\s*)+$/
      if (regex.test(this.new_thresholds)) {
        this.$refs.AddThresholdButton.classList.add('is-active')
        this.$refs.AddThresholdInput.classList.remove('is-danger')
        this.$refs.AddThresholdInvalidText.classList.add('is-hidden')
        return true
      } else {
        this.$refs.AddThresholdButton.classList.remove('is-active')
        if (onSubmit == true) {
          this.$refs.AddThresholdInput.classList.add('is-danger')
          this.$refs.AddThresholdInvalidText.classList.remove('is-hidden')
        }
        return false
      }


    },

    async getThresholds() {
      const url = '/thresholds.json/?coin_id=' + this.coin['id']
      const {data} = await this.$http.get(url)
      this.thresholds = data['thresholds']
    },

    async removeAll() {
      const url = '/thresholds/destroy_all.json?coin_id=' + this.coin['id']
      const {data} = await this.$http.delete(url)
      this.thresholds = data['thresholds']
      this.$emit("thresholdChanged", this.coin['id'], this.thresholds.length);
      this.$refs.AddThresholdInput.focus()
    }

  }

}
</script>