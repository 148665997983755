<template>

    <div>
        <div class="field">
            <div class="control is-medium" ref="coinSearchControl">
                <input class="input is-medium" type="text" name="thresholds"
                       placeholder="Search for coin"
                       v-on:keyup="getCoins" v-model="search" ref="coinSearch">
            </div>
        </div>
        <div v-for="coin in coins" :key="coin.id" class="coin" :ref="'coin-' + coin.id">
            <div class="coin-header is-clearfix">

                <img :src="coin['image']" class="is-pulled-left"/>
                <div class="coin-detail is-pulled-left">
                    {{ coin['name'] }}
                </div>

                <a class="is-small is-pulled-right back-link is-hidden"
                   :ref="'addedButton-' + coin.id"
                   v-on:click="$emit('toggleCoinChooser')">

                    <button class="button added-button is-small">
                        <i class="fas fa-check"></i>
                        OK
                    </button>

                </a>

                <button class="button add-button is-small is-pulled-right" v-on:click="toggleCrud(coin.id)"
                        :ref="'addButton-' + coin.id">Add
                </button>
            </div>
            <div class="coin-edit">
                <CoinCrud class="CoinCrud" action='Add' :coin=coin
                          @thresholdChanged="thresholdChanged"
                          :ref="'coinCrudComponent' + coin['id']"></CoinCrud>
            </div>
        </div>

    </div>

</template>

<script>
import CoinCrud from "./coin_crud";

export default {

  components: {CoinCrud},

  data: function () {
    return {
      coins: [],
      search: ''
    }
  },

  mounted() {
    this.activate()
    this.getCoins()
  },

  computed: {
    canAddCoins() {
      return this.$store.getters.canAddCoins
    }
  },

  watch: {
    canAddCoins() {
      this.activate()
      this.getCoins()
    }
  },

  methods: {

    toggleCrud(coin_id) {
      this.coins = [this.coins.find(x => x.id === coin_id)]
      this.$refs["coin-" + coin_id][0].classList.toggle('is-active')
      this.$refs["coinCrudComponent" + coin_id][0].toggleDialogue()
    },

    thresholdChanged(coin_id, amount) {
      let add = this.$refs['addButton-' + coin_id][0]
      let added = this.$refs['addedButton-' + coin_id][0]

      if (amount == 0) {
        add.classList.remove('is-hidden')
        added.classList.add('is-hidden')
      } else {
        add.classList.add('is-hidden')
        added.classList.remove('is-hidden')
        added.style.animation = 'none';
        added.offsetHeight; /* trigger reflow */
        added.style.animation = null;
      }

      this.$emit("thresholdChanged", coin_id);
    },

    activate() {
      if (this.$store.getters.canAddCoins) {
        this.$refs.coinSearch.classList.remove('is-hidden')
        this.$refs.coinSearch.value = ''
        this.$refs.coinSearch.focus()
        if (this.$isMobile()) {
          this.$refs.coinSearch.scrollIntoView();
        }
      }else{
        this.$refs.coinSearch.classList.add('is-hidden')
      }


    },

    async getCoins() {
      if(this.$store.getters.canAddCoins) {
        this.$refs.coinSearchControl.classList.toggle('is-loading')
        let url = '/coins.json' + '/?search=' + this.search
        const {data} = await this.$http.get(url)
        this.coins = data
        this.$refs.coinSearchControl.classList.toggle('is-loading')
      }

    },


  }

}
</script>

<style scoped>

</style>